module.exports = {
  background: '#FFFFFF',
  backgroundDark: '#FFE119',

  text: '#333333',

  // Main text
  primary: '#000000',
  // underlines, H, a, a hover
  primaryLight: '#FFE119',
  primaryDark: '#1C27FF',

  secondary: '#1F07B2',
  // middle block
  secondaryLight: '#FFE119', 
  
  secondaryDark: '#220f9e',
};
